<template>
	<div class="relative">
		<div ref="inputEl" class="form-group">
			<input type="text" class="form-control" placeholder="" v-model="query" />
		</div>

		<ul v-if="showDropdown" class="list-group fixed w-[90%] max-w-[80%] md:max-w-[350px] bg-white z-50" :class="`top-[${top+50}px] left-[${left}px]`">
			<template v-if="suggestions.length">
				<li
					@click="handleSelected(item?.description, item)"
					v-for="item in suggestions"
					class="list-group-item"
					style="cursor: pointer; font-size: 12px"
					:key="item?.place_id"
					>
					{{ item?.description }}
				</li>
			</template>
      
		</ul>
	</div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import {
  usePlacesAutocomplete,
  getGeocode,
  getLatLng
} from 'v-use-places-autocomplete'

export default defineComponent({
  name: 'AddressAutocomplete',
  props: ['officeBranch'],
  setup (props, { emit }) {
    const inputEl = ref(null)
    const top = ref(0)
    const left = ref(0)
    const officeBranch = props?.officeBranch
    const query = ref('')
    query.value = officeBranch
    const showDropdown = ref(false)
    const { suggestions } = usePlacesAutocomplete(query, {
      debounce: 500,
      minLengthAutocomplete: 3
    })
    const placeSelected = ref('')

    async function handleSelected (selectedAddressValue, item) {
      placeSelected.value = selectedAddressValue
      const parameter = {
        address: item?.description,
        placeId: item?.placeId
      }
      const results = await getGeocode(parameter)
      const latLng = await getLatLng(results[0])

      query.value = selectedAddressValue

      const locationInformation = {
        address: selectedAddressValue,
        geoLocation: {
          lat: latLng.lat,
          lng: latLng.lng
        }
      }

      emit('autoCompleteAddress', locationInformation)
      showDropdown.value = false
      selectedAddressValue = null
    }

    watch(query, (newVal, oldVal) => {
      if(newVal != placeSelected.value) {
        showDropdown.value = true
      }
      if(!query.value.length) emit('autoCompleteAddress', null)
    })

    // watch(suggestions, () => {
    //   if(suggestions) {
    //     showDropdown.value = true
    //   } else {
    //     showDropdown.value = false
    //   }
    // })

    watch(showDropdown, () => {
      if(showDropdown) {
        const rect = inputEl.value.getBoundingClientRect()
        top.value = rect.top
        left.value = rect.left
      }
    })

    return {
      query,
      suggestions,
      officeBranch,
      handleSelected,
      showDropdown,
      inputEl, top, left
    }
  },
  emits: ['autoCompleteAddress']
})
</script>
