import {ref} from 'vue'
import moment from 'moment'
import {axiosInstance as axios} from '@/plugins/axios.js'
import VueToastr from 'vue-toastr'
import Swal from 'sweetalert2'
import {useModal} from '@/composables/core/modal'


const { closeModal } = useModal()
const branches = ref([])

const form = ref({
	name: '',
	address: ''
})
const locationLat = ref('')
const locationLng = ref('')


export const useBranch = () => {
	const processing = ref(false)
	const addBranch = (companyId, toastr) => {
		processing.value = true
		const payload = {
			name: form.value.name,
			address: form.value.address,
			location: `${locationLat.value},${locationLng.value}`,
			description: form.value.name
		}
		axios.post(`/v1/corporates/${companyId}/office-branches`, payload)
		.then(() => {
			toastr.s('Work-branch saved successfully!')
			fetchBranches(companyId)
		})
		.catch((err) => {
			toastr.e(err?.response?.data?.message, 'Error')
		})
		.finally(() => {
			processing.value = false
			form.value.name = ''
			form.value.address = ''
			closeModal()
		})
	}
	const fetchBranches = (companyId) => {
		processing.value = true
		axios.get(`/v1/corporates/${companyId}/office-branches`)
        .then((res) => {
        //   branches.value = res?.data?.data || []
		  if(res.data.data) {
			branches.value = res?.data?.data
		  } else {
			branches.value = []
		  }
        //   this.work_branch_count = res?.data?.metadata?.total
        })
        .catch((error) => {
        //   this.$toastr.e(error?.response?.data?.message, 'Error')
			Swal.fire({ icon: 'error', title: 'Error', text: error?.response?.data?.message})
        })
        .finally(() => {
        //   this.fetchingWorkBranches = false
		processing.value = false
        })
	}
	const deleteBranch = async (companyId, work_branch, swal) => {
		await swal({
			icon: 'question',
			title: 'Please Confirm',
			text: 'Are you sure you want to delete this work branch?',
			showConfirmButton: true,
			showCancelButton: true,
			preConfirm: () => {
				return axios
				.delete(
					`/v1/corporates/${companyId}/office-branches/${work_branch.id}`
				)
				.catch((error) => {
					const msg =
					error.response && error.response.data
						? error.response.data.message
						: 'An error occured, please try again.'

					swal().showValidationMessage(msg)
				})
				.finally(() => {
					fetchBranches(companyId)
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				swal({
				icon: 'success',
				title: 'Success',
				text: '\'Work branch was successfully deleted',
				showCloseButton: true
				})
			}
		})
	}

	return { processing, addBranch, fetchBranches, form, locationLat, locationLng, branches, deleteBranch}
}

export const useUpdateBranch = () => {
	const processing = ref(false)
	const updateBranch = (companyId, branchId, toastr) => {
		processing.value = true
		const payload = {
			name: form.value.name,
			address: form.value.address,
			location: `${locationLat.value},${locationLng.value}`,
			description: form.value.name
		}
		
		axios.patch(
			`/v1/corporates/${companyId}/office-branches/${branchId}`,
			payload
		)
		.then(() => {
			toastr.s('Work branch was successfully updated ', 'Success')
			closeModal()
			useBranch().fetchBranches(companyId)
		})
		.catch((error) => {
			toastr.e(error?.response?.data?.message, 'Error')
		})
		.finally(() => {
			// this.$bvModal.hide('editWorkBranchModal')
			// this.processingWorkBranch = false
			// this.loadCorporateWorkBranches()
			processing.value = false
		})
	}

	return { processing, updateBranch, form, locationLat, locationLng }
}