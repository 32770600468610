<template>
	<modalLayout title="Edit Office Branch">
		<form @submit.prevent="updateBranch(modal_prop.companyId, modal_prop.branch_obj.id, $toastr)">
			<div class="row" >
				<div class="col-12">
					<div class="col-12">
						<div class="form-group">
							<label class="form-label"> Name </label>
							<input v-model.trim="form.name" required type="text" class="form-control" />
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label class="form-label"> Office branch</label>
							<div >
								<AddressAutocomplete @autoCompleteAddress="selectedWorkBranch" :officeBranch="form.address" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-100">
				<button :disabled="processing || !formValid" type="submit" class="btn btn-primary w-100">
					{{ processing ? 'Processing...' : 'Update work branch' }}
				</button>
			</div>
		</form>
	</modalLayout>
</template>

<script setup>
import {defineProps, computed } from 'vue'
import modalLayout from '@/layouts/core/modal.vue'
import AddressAutocomplete from '@/views/Company/components/AddressAutocomplete.vue'
import {useUpdateBranch} from '@/composables/backend/companies/branch'

const { processing, form, locationLat, locationLng, updateBranch } = useUpdateBranch()
const props = defineProps(['modal_prop'])

const formValid = computed(() => {
	return !!(form.value.name && form.value.address)
})
const selectedWorkBranch = (value) => {
	if (value) {
		form.value.address = value?.address
		locationLat.value = value?.geoLocation?.lat
		locationLng.value = value?.geoLocation?.lng
	} else {
		form.value.address = ''
		locationLat.value = ''
		locationLng.value = ''
	}
}

</script>